<template>
  <v-container>
    <div class="d-flex flex-row">
      <PageTitle
        :text="`List Of Recorded Sales`"
        class="font font-weight-bold mt-n2 d-flex flex-column"
      />
      <v-btn small icon :loading="isPageLoading" fab @click.stop="refreshPage">
        <i class="material-icons-outlined">refresh</i>
      </v-btn>
    </div>
    <div class="d-flex flex-row">
      <div class="d-flex ml-2 font font-weight-medium flex-row flex-grow-1">
        <span class="text-uppercase font-size-md"
          >Total Overall Sales (Lubes + Fuels + Shops)</span
        >
        <v-chip class="font font-weight-bold ml-4" small color="primary" label>
          <span class=" font">{{
            events.reduce(
              (acc, event) => (acc += parseFloat(event.totalAmount)),
              0
            )
              | currencyFormat(
                currency && currency.length > 0 ? currency[0].code : "GHS"
              )
          }}</span>
        </v-chip>
      </div>
    </div>
    <v-row>
      <v-col cols="12" md="12" lg="12" sm="12">
        <v-card class="pa-3 box-shadow-light">
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn
                outlined
                class="mr-4 font"
                color="grey darken-2"
                @click="setToday"
              >
                Today
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small>
                  arrow_back
                </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small> arrow_forward </v-icon>
              </v-btn>
              <v-toolbar-title class="font" v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right>
                      mdi-menu-down
                    </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title class="font">Day</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title class="font">Week</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title class="font">Month</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title class="font">4 days</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="750">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              class="font-weight-bold font"
              style="font-size: 14px;"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @moved="onCalendarChange"
              @change="updateRange"
            >
            </v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
              style="box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14),0 9px 46px 8px rgba(0,0,0,0.12),0 11px 15px -7px rgba(0,0,0,0.2)"
            >
              <v-card min-width="380px" flat>
                <v-toolbar flat dense color="rgba(238, 238, 238, 0.6)">
                  <v-toolbar-title>
                    <span class="font black--text font-size-md font-weight-bold"
                      >Sales for {{ currentSaleType }} ({{
                        selectedEvent.start | fromNow
                      }})</span
                    >
                  </v-toolbar-title>
                  <v-spacer />
                  <v-btn
                    color="black"
                    @click="selectedOpen = false"
                    icon
                    small
                    class="mr-2"
                  >
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text
                  v-if="['fuels', 'lubes'].includes(currentSaleType)"
                >
                  <v-alert
                    v-if="
                      currentSaleType === 'lubes' &&
                        salesList.some(sale => !sale.isFinalized)
                    "
                    dense
                    type="warning"
                  >
                    <span class=" font font-weight-medium font-size-md">
                      Sales pending approval
                    </span>
                  </v-alert>
                  <v-alert
                    v-if="
                      currentSaleType === 'fuels' &&
                        salesListFuels.some(sale => !sale.isApproved)
                    "
                    dense
                    type="warning"
                  >
                    <span class=" font font-weight-medium font-size-md">
                      Sales pending approval
                    </span>
                  </v-alert>
                  <div class="d-flex flex-column flex-grow-1 pb-3">
                    <p>
                      <v-chip
                        label
                        small
                        class="font white--text font-weight-bold font-size-md mt-n1"
                        color="primary"
                        >Total
                        {{
                          currentSaleType === "fuels"
                            ? "Fuel Sales Revenue (Fuel Sales + Credit Collections - RTT)"
                            : "Lubes Sales"
                        }} </v-chip
                      ><span class="pl-3 pt-2 font-weight-bold font-size-md ">{{
                        selectedEvent.totalAmount | currencyFormat
                      }}</span>

                      <v-chip
                        label
                        small
                        class="font ml-5 white--text font-weight-bold font-size-md mt-n1"
                        color="primary"
                        >Total
                        {{ currentSaleType === "fuels" ? "Meter" : "Lubes" }}
                        Sold</v-chip
                      ><span class="pl-3 pt-2 font-weight-bold font-size-md ">{{
                        selectedEvent.totalSold
                      }}</span>
                    </p>
                  </div>
                  <v-data-table
                    :items="
                      currentSaleType !== 'fuels' ? salesList : salesListFuels
                    "
                    :headers="
                      currentSaleType !== 'fuels'
                        ? headers
                        : [
                            { text: 'Nozzle', value: 'nozzleId' },
                            {
                              text: 'Price Change',
                              value: 'hasPriceChanged'
                            },
                            { text: 'Closing Meter', value: 'closingMeter' },
                            ...headers
                          ]
                    "
                    :options.sync="options"
                    :server-items-length="paginate.total"
                    :loading="isListLoading"
                    class="font font-weight-medium"
                    :items-per-page="paginate.limit"
                    :footer-props="{
                      itemsPerPageOptions: [2, 3, 5]
                    }"
                  >
                    <template #item.productId="{item}">
                      <span>{{ item.productId.name }}</span>
                    </template>
                    <template #item.quantitySold="{item}">
                      <span v-if="item.quantitySold">{{
                        item.quantitySold
                      }}</span>
                      <span v-else> {{ item.meterSold }} L</span>
                    </template>
                    <template #item.nozzleId="{item}">
                      <span class=" font font-weight-medium font-size-sm">{{
                        item.nozzleId.name
                      }}</span>
                    </template>

                    <template #item.hasPriceChanged="{item}">
                      <v-chip label small color="primary">
                        {{ item.hasPriceChanged ? "Yes" : "No" }}
                      </v-chip>
                    </template>
                    <template #item.closingMeter="{item}">
                      <span>{{ item.closingMeter }} L</span>
                    </template>
                  </v-data-table>
                </v-card-text>
                <v-card-text v-else>
                  <v-alert
                    v-if="salesListShops && !salesListShops.isApproved"
                    dense
                    type="warning"
                    small
                  >
                    Sales are pending approval from manager
                  </v-alert>
                  <div class="d-flex flex-column flex-grow-1 pb-3">
                    <div class="d-flex flex-row">
                      <v-chip
                        label
                        small
                        class="font white--text font-weight-bold font-size-md mt-1"
                        color="primary"
                        >Total Sales</v-chip
                      ><span class="pl-3 pt-2 font-weight-bold font-size-md ">{{
                        selectedEvent.totalAmount | currencyFormat
                      }}</span>
                      <v-chip
                        v-if="salesListShops && salesListShops.cashDeposit"
                        label
                        small
                        class="font ml-5 white--text font-weight-bold font-size-md mt-1"
                        color="primary"
                        >Cash Deposit</v-chip
                      ><span class="pl-3 pt-2 font-weight-bold font-size-md ">{{
                        salesListShops.cashDeposit | currencyFormat
                      }}</span>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row></v-container
  >
</template>

<script>
import { Api } from "../../api/Api";
import moment from "moment";
import { currencyFormat } from "../../filters/currencyFormat";
import { debounce } from "lodash";
import { mapGetters } from "vuex";
export default {
  components: {
    PageTitle: () => import(`../../components/PageTitle`)
  },
  data: () => ({
    showSnackBar: "",
    isPageLoading: false,
    isListLoading: false,
    message: "",
    status: "",
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days"
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    salesList: [],
    salesListFuels: [],
    salesListShops: {},
    options: {},
    currentSaleType: "fuels",
    paginate: {
      total: 0,
      page: 1,
      limit: 3
    },
    headers: [
      {
        text: "Product",
        value: "productId"
      },
      {
        text: "Qty Sold",
        value: "quantitySold"
      }
    ],
    // this is for fuels only by date
    overallSalesRecords: {
      totalTrackAmount: {}
    }
  }),
  filters: {
    currencyFormat,
    fromNow(value) {
      return moment(value).fromNow();
    }
  },
  mounted() {
    this.$refs.calendar.checkChange();
  },
  computed: {
    ...mapGetters({
      currency: "settings/getSystemCurrency"
    })
  },
  watch: {
    options: {
      handler(options) {
        if (this.selectedEvent) {
          let obj = {
            start: this.selectedEvent.start,
            page: options.page,
            limit: options.itemsPerPage
          };
          switch (this.selectedEvent.type) {
            case "fuels":
              this.salesListByDateFuels(obj);
              break;
            default:
              this.salesListByDate(obj);
              break;
          }
        }
      },
      deep: true
    }
  },
  methods: {
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      this.paginate = { page: 1, limit: 3, total: 0 };
      event.type === "lubes"
        ? this.salesListByDate({ start: event.start, ...this.paginate })
        : event.type === "fuels"
        ? this.salesListByDateFuels({
            start: event.start,
            ...this.paginate
          })
        : this.salesListByDateShops({ start: event.start });

      this.currentSaleType = event.type;
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start }) {
      this.getAllSalesShops({ date: start.date });
      this.getAllSalesFuels({ date: start.date });
      this.getAllSales({ date: start.date });
    },
    onCalendarChange({ date }) {
      this.date = date;
      if (this.date) {
        this.getAllSalesShops({ date });
        this.getAllSales({ date });
        this.getAllSalesFuels({ date });
      }
    },
    async getAllSales({ date }) {
      try {
        this.isPageLoading = true;
        const response = await Api().get(`/sales/by/date?date=${date}`);
        if (response) {
          this.events = response.data.map(event => {
            return {
              name: `Lubes - ${currencyFormat(event.total)}`,
              start: moment(event.date)
                .utc()
                .format("YYYY-MM-DD"),
              totalSold: event.totalSold,
              totalAmount: event.total,
              color: "accent",
              type: "lubes"
            };
          });
          this.isPageLoading = false;
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message = "Unable to fetch sales at this time";
        this.success = true;
        this.isPageLoading = false;
      }
    },
    getAllSalesFuels: debounce(async function({ date }) {
      try {
        this.isPageLoading = true;
        // const response = await Api().get(`/sales/fuels/by/date?date=${date}`);
        const response = await Api().get(
          `/sales/fuels/by/date/using/overall/records?date=${date}`
        );
        if (response) {
          this.isPageLoading = false;
          this.events = [
            ...this.events,
            ...new Set(
              response.data.map(event => {
                return {
                  name: `Fuels  - ${currencyFormat(
                    event.totalTrackAmount?.totalFuelSales
                  )}`,
                  start: moment(event.salesDate)
                    .utc()
                    .format("YYYY-MM-DD"),
                  totalSold:
                    event.totalSold?.reduce(
                      (acc, cur) => (acc += Number(cur.totalSold)),
                      0
                    ) ?? 0,
                  totalAmount: event.totalTrackAmount?.totalFuelSales,
                  color: "primary",
                  type: "fuels"
                };
              })
            )
          ];
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message = "Unable to fetch sales at this time";
        this.success = true;
        this.isPageLoading = false;
      }
    }, 40),
    getAllSalesShops: debounce(async function({ date }) {
      try {
        this.isPageLoading = true;
        const response = await Api().get(`/sales/shops/by/date?date=${date}`);
        if (response) {
          this.isPageLoading = false;
          this.events = [
            ...this.events,
            ...new Set(
              response.data.map(event => {
                return {
                  name: `Shop - ${currencyFormat(event.salesAmount)}`,
                  start: moment(event.salesDate)
                    .utc()
                    .format("YYYY-MM-DD"),
                  totalSold: 0,
                  totalAmount: event.salesAmount,
                  color: event.isApproved ? "success" : "warning",
                  type: "shops"
                };
              })
            )
          ];
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message = "Unable to fetch sales at this time";
        this.success = true;
        this.isPageLoading = false;
      }
    }, 60),
    async salesListByDate({ start, page, limit }) {
      try {
        this.isListLoading = true;
        const response = await Api().get(
          `/sales/by/date/${start}?isPaginated=true&page=${page}&limit=${limit}`
        );
        if (response) {
          this.isListLoading = false;
          this.salesList = response.data.paginateObj.docs;
          const { page, total, limit } = response.data.paginateObj;
          this.paginate = { page, total, limit: parseInt(limit) };
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message = "Unable to fetch sales at this time";
        this.success = true;
        this.isListLoading = false;
      }
    },
    async salesListByDateFuels({ start, page, limit }) {
      try {
        this.isListLoading = true;
        const response = await Api().get(
          `/sales/fuels/by/date/${start}?isPaginated=true&page=${page}&limit=${limit}`
        );
        if (response) {
          this.isListLoading = false;
          this.salesListFuels = response.data.paginateObj.docs;
          const { page, total, limit } = response.data.paginateObj;
          this.paginate = { page, total, limit: parseInt(limit) };
          //await this.overallFuelSales({ start });
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message = "Unable to fetch sales at this time";
        this.success = true;
        this.isListLoading = false;
      }
    },
    async salesListByDateShops({ start }) {
      try {
        this.isListLoading = true;
        const response = await Api().get(`/sales/shops/by/date/${start}`);
        if (response) {
          this.isListLoading = false;
          this.salesListShops = response.data;
        }
      } catch (e) {
        this.showSnackBar = true;
        this.message = `Unable to fetch sales at this time`;
        this.success = true;
        this.isListLoading = false;
      }
    },
    refreshPage() {
      const date = { date: this.date ?? moment().format("YYYY-MM-DD") };
      this.updateRange({ start: date });
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    }
  },
  created() {
    this.$store.dispatch("settings/systemCurrency");
  }
};
</script>

<style scoped></style>
